import React from "react";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import * as ROUTES from "../../constants/routes.js";
import AuthUserProvider from "../authentication-no-ui/AuthUserProvider.js";
import FirebaseProvider from "../infra-no-ui/firebase/FirebaseProvider.js";
import BasePage from "../common/page/BasePage.js";
import DefaultLanguageSwitcher from "../common/page/DefaultLanguageSwitcher.js";
import FooterPortal from "../common/page/FooterPortal.js";
import TranslationProvider from "../infra-no-ui/translation/TranslationProvider.js";
import FirebaseAuthProvider from "../authentication-no-ui/FirebaseAuthProvider.js";
import NotFoundPage from "./NotFoundPage.js";
import AuthenticatedApolloProvider from "../authentication-no-ui/AuthenticatedApolloProvider.js";
import MissingProfileInterceptor from "../authentication-ui/MissingProfileInterceptor.js";
import DisconnectedModal from "../authentication-ui/DisconnectedModal.js";
import ResetPasswordPage from "../authentication-ui/ResetPasswordPage.js";
import FirebaseErrorPage from "../authentication-ui/FirebaseErrorPage.js";
import DatePickerLocaleProvider from "./DatePickerLocalizationProvider.js";
import useShowLoader from "../common/loading-widgets/useShowLoader.js";
import Loader from "../common/loading-widgets/Loader.js";
import Menu from "../menu/Menu.js";
import Footer from "../footer/Footer.js";
import SignOutPage from "../authentication-ui/SignOutPage.js";
import SignUpPage from "../authentication-ui/SignUpPage.js";
import SignInPage from "../authentication-ui/SignInPage.js";
import JobsPage from "../jobs/view/JobsPage.js";
import CandidatesPage from "../candidates/view/CandidatesPage.js";
import ProfilePage from "../profile/view/ProfilePage.js";
import JobDashboardPage from "../job-dashboard/view/JobDashboardPage.js";
import { apolloCacheManager } from "./apolloCacheManager.js";
import useQueryEmployerByEmail from "../employers/useQueryEmployerByEmail.js";
import JobUpdatePage from "../jobs/mutate/JobUpdatePage.js";
import AdminPage from "../admin/view/AdminPage.js";
import PaymentPage from "../admin/view/PaymentPage.js";
import ApplicationCountByJobPage from "../admin/view/ApplicationCountByJobPage.js";
import MenuLogo from "../menu/MenuLogo.js";
import HeaderNavbar from "../menu/HeaderNavbar.js";
import HeaderPortal from "../common/page/HeaderPortal.js";
import TranslatedContentError from "../common/error-boundaries/TranslatedContentError.js";
import ErrorBoundary from "../common/error-boundaries/ErrorBoundary.js";
import ConnectionModePage from "../authentication-ui/ConnectionModePage.js";
import ConnectionModeSignUpPage from "../authentication-ui/ConnectionModeSignUpPage.js";
import ApolloErrorLogger from "../common/error-boundaries/ApolloErrorLogger.js";
import ErrorTestPage from "./ErrorTestPage.js";
import RecruiterListPage from "../admin/view/RecruiterListPage.js";
import ApplicationListPage from "../admin/view/ApplicationListPage.js";
import EmployerListPage from "../admin/view/EmployerListPage.js";
import DetailedJobListPage from "../admin/view/DetailedJobListPage.js";
import ChatGptPage from "../chatgpt/view/ChatGptPage.js";

export default function AppRouter() {
  return <Router><AppRouterContent/></Router>
}

function AppRouterContent() {
  return (
    // <Router> must come before BasePage, because BasePage ultimately renders links
    // Display BasePage before everything else, so that we can use it to display translation and authentication errors.
    // BasePage will use portals to display menu and footer, which depend on translation and authentication that are not yet ready
    <BasePage header={<HeaderNavbar logo={<MenuLogo />} />}>
      <Loader>
        <TranslationProvider useShowLoader={useShowLoader}>
          <ErrorBoundary component={<TranslatedContentError />}>
            <DatePickerLocaleProvider>
              <FirebaseProvider errorComponent={FirebaseErrorPage}>
                <FirebaseAuthProvider disconnectedModal={DisconnectedModal}>
                  <AuthenticatedApolloProvider
                    cacheManager={apolloCacheManager()}
                  >
                    {/* At this point we can log frontend errors using Apollo because it is now available */}
                    <ErrorBoundary
                      component={<TranslatedContentError />}
                      logger={ApolloErrorLogger}
                    >
                      <AuthUserProvider
                        useQueryProfile={useQueryEmployerByEmail}
                      >
                        <DefaultLanguageSwitcher>
                          <HeaderPortal
                            component={
                              <HeaderNavbar
                                logo={<MenuLogo />}
                                menu={<Menu />}
                              />
                            }
                          />
                          <Routes>
                            {/* Put that before MissingProfileInterceptor because missing profile must not prevent from signing out */}
                            <Route
                              exact
                              path={ROUTES.SIGN_OUT}
                              element={<SignOutPage/>}
                            />
                            {/* Don't go deeper if user is authenticated but has no profile */}
                            <Route element={<MissingProfileInterceptor/>}>
                              {/* Try to keep declarations here and in routes.js in the same order, for maintainability */}
                              <Route
                                exact
                                path={ROUTES.CONNECTION_MODE}
                                element={<ConnectionModePage/>}
                              />
                              <Route
                                exact
                                path={ROUTES.CONNECTION_MODE_SIGN_UP}
                                element={<ConnectionModeSignUpPage/>}
                              />
                              <Route
                                exact
                                path={ROUTES.SIGN_IN}
                                element={<SignInPage/>}
                              />
                              <Route
                                exact
                                path={ROUTES.SIGN_UP}
                                element={<SignUpPage/>}
                              />
                              <Route
                                exact
                                path={ROUTES.HOME}
                                element={<JobsPage/>}
                              />
                              <Route
                                exact
                                path={ROUTES.EMPLOYER_DASHBOARD}
                                element={<JobsPage/>} /* Until a more elaborate dashboard page is implemented */
                              />
                              <Route
                                exact
                                path={ROUTES.EMPLOYER_CHATGPT}
                                element={<ChatGptPage/>}
                              />
                              <Route
                                exact
                                path={ROUTES.EMPLOYER_ACTIVE_JOBS}
                                element={<JobsPage/>}
                              />
                              <Route
                                exact
                                path={ROUTES.EMPLOYER_JOB_CREATE}
                                element={<JobUpdatePage/>}
                              />
                              <Route
                                exact
                                path={ROUTES.EMPLOYER_JOB_UPDATE}
                                element={<JobUpdatePage/>}
                              />
                              <Route
                                exact
                                path={ROUTES.EMPLOYER_JOB_DASHBOARD}
                                element={<JobDashboardPage/>}
                              />
                              <Route
                                exact
                                path={ROUTES.EMPLOYER_JOB_CANDIDATES}
                                element={<CandidatesPage/>}
                              />
                              <Route
                                exact
                                path={ROUTES.EMPLOYER_PROFILE}
                                element={<ProfilePage/>}
                              />
                              <Route
                                exact
                                path={ROUTES.EMPLOYER_ADMIN}
                                element={<AdminPage/>}
                              />
                              <Route
                                exact
                                path={ROUTES.EMPLOYER_ADMIN_PAYMENT}
                                element={<PaymentPage/>}
                              />
                              <Route
                                exact
                                path={
                                  ROUTES.EMPLOYER_ADMIN_APPLICATION_COUNT_BY_JOB
                                }
                                element={<ApplicationCountByJobPage/>}
                              />
                              <Route
                                exact
                                path={ROUTES.EMPLOYER_ADMIN_RECRUITERS}
                                element={<RecruiterListPage/>}
                              />
                              <Route
                                exact
                                path={ROUTES.EMPLOYER_ADMIN_APPLICATIONS}
                                element={<ApplicationListPage/>}
                              />
                              <Route
                                exact
                                path={ROUTES.EMPLOYER_ADMIN_EMPLOYERS}
                                element={<EmployerListPage/>}
                              />
                              <Route
                                exact
                                path={ROUTES.EMPLOYER_ADMIN_JOBS}
                                element={<DetailedJobListPage/>}
                              />
                              <Route
                                exact
                                path={ROUTES.RESET_PASSWORD}
                                element={<ResetPasswordPage/>}
                              />
                              <Route
                                exact
                                path={ROUTES.ERROR_TEST}
                                element={<ErrorTestPage/>}
                              />
                              {/* Catch-all rule when none of the above matches */}
                              <Route path="*" element={<NotFoundPage/>} />
                            </Route>
                          </Routes>
                          <FooterPortal component={<Footer />} />
                        </DefaultLanguageSwitcher>
                      </AuthUserProvider>
                    </ErrorBoundary>
                  </AuthenticatedApolloProvider>
                </FirebaseAuthProvider>
              </FirebaseProvider>
            </DatePickerLocaleProvider>
          </ErrorBoundary>
        </TranslationProvider>
      </Loader>
    </BasePage>
  );
}
